import Api from '@/services/Api'

const resource = '/users'

export default {

  getOrders(params = {}) {
    return Api().get(`${resource}/orders`, {
      params: params
    })
  },
  registerUser(credentials) {
    return Api().post(`${resource}/register`, credentials)
  },
  userDelete(credentials) {
    return Api().post(`${resource}/delete/organization`, credentials)
  },
  userModify(credentials){
    return Api().post(`${resource}/storprof/modify`, credentials)
  },
  modifyUser(credentials) {
    return Api().post(`${resource}/modify`, credentials)
  },
  modifyUser2(credentials) {
    return Api().post(`${resource}/modify2`, credentials)
  },
  confirmToken(params = {}) {
    return Api().get(`${resource}/confirm/registration`, {
      params: params
    })
  },
  getUsersByPage(params = {}) {
    return Api().get(`${resource}?size=12`, {
      params: params
    })
  },
  getStudentRequestByPage(params = {}) {
    return Api().get(`${resource}/student/requests?size=12`, {
      params: params
    })
  },

  userDetails(id){
    return Api().get(`${resource}/${id}`)
  },

  getNewRequestCount(params = {}) {
    return Api().get(`${resource}/student/newrequestcount`, {
      params: params
    })
  },

  getCountries() {
    return Api().get(`/countries`)
  },
  getStates(countryid) {
    return Api().get(`/states/${countryid}`)
  },
  requestOrganization(credentials) {
    return Api().post(`/organization/request`, credentials)
  },
  confrimRequests(credentials) {
    return Api().post(`${resource}/confirm/requests`, credentials)
  },
  deleteRequests(credentials) {
    return Api().post(`/organization/strequest/back`, credentials)
  },
  passwordChange(credentials) {
    return Api().post(`${resource}/password/update`, credentials)
  }
  ,
  me(credentials) {
    // eslint-disable-next-line new-cap
    return Api().get('user/me', credentials)
  },
  cardConfirm(credentials) {
    return Api().post(`${resource}/card/confirm`, credentials)
  }
}
