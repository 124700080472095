<template>
    <div class="Home">
      <page-header/>
      <section class="uk-section uk-section-large">
        <div class="uk-container uk-height-medium">
          <div class="uk-grid-medium uk-child-width-1-1" uk-grid>
            <div class="uk-text-center">
              <div class="uk-margin" v-if="error!==null">
                <div class="uk-alert-danger" uk-alert>
                  <p v-html="error" class="uk-text-center"></p>
                </div>
              </div>
              <div class="uk-margin" v-if="message!==null">
                <img width="150px" src="../assets/check-circle.png">
                <h1 class="uk-margin-remove" v-html="message"></h1>
                <h3 class="uk-margin-bottom">
                  <p> {{$t("confirmAccount.youcan")}}
                    <a href="app://deeplink" v-if="$mq == 'mobile' || $mq == 'tablet' ">{{$mq}}</a>
                    <router-link title="Login" tag="a" v-else :to="{name: 'login'}">
                      {{$t("confirmAccount.clickHere")}}</router-link>
                    {{$t("confirmAccount.tologin")}}
                  </p>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </section>
      <page-footer/>
    </div>


</template>
<script>

  // import Layout from '../layouts/Layout'
  import UserService from '@/services/UserService'
  import PageHeader from '@/components/Homecmp/Header.vue'
  import PageFooter from '@/components/Homecmp/Footer.vue'

  export default {

    components: {
      PageHeader,
      PageFooter,
      // Layout
    },
    created() {
      // refresh hiihed urlparamaas hamarch default utguudaa oorchiloh
      if (this.$route.query.token) {
        this.confirmtoken(this.$route.query.token)
      } else {
        this.$router.push({name: 'home'})
      }
    },
    data() {
      return {
        error: null,
        message: null
      };
    },
    methods: {

      async confirmtoken(token) {
        try {
          const response = await UserService.confirmToken({
            token: token
          });
          if (response.data.status === 200) {
            // this.$router.push({ name: 'login' })
            if (response.data.success) {
              this.message = response.data.message
            } else {
              this.error = response.data.message
            }
          } else {
            this.error = response.data.message
          }


        } catch (error) {
          // console.log(error.response)
          this.error = error.response.data.message;
          this.scrollToTop()
        }

      }

    }
  }
</script>

<style scoped>
</style>
